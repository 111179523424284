import React from 'react';
import logo from '../assets/text-tilde-dark.svg';
import gradient from '../assets/gradient.jpg';
import '../styles/Home.scss';
import Countdown from 'react-countdown';

const ogDate = new Date("2023-03-16T19:00:00.000Z");
const seakDate = new Date("2023-03-16T22:00:00.000Z");
const reserveDate = new Date("2023-03-17T22:00:00.000Z");
const generalDate = new Date("2023-04-17T22:00:00.000Z");

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return 'Started';
  } else {
    // Render a countdown
    return <span>{days} day{days > 0 && 's'}, {hours} hour{hours > 0 && 's'}, {minutes} min{minutes > 0 && 's'}, {seconds} sec</span>;
  }
};

const Home = () => {
  return ( 
    <>
    <div className="section home-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center">
              <img src={logo} alt='SEAK' className="logo" draggable={false}/>
              <h2>Filter through the noise.</h2>
            </div>
            <div className="col-md-6">
              <div className='countdown'>
                {/* Check <a href="https://twitter.com/SEAKNFT">Twitter</a> for relevant updates. */}
                {/* <div className="mint-countdown"><b>OG Mint</b>: <Countdown renderer={renderer} date={ogDate}></Countdown></div>
                <div className="mint-countdown"><b>SEAKLIST Mint</b>: <Countdown renderer={renderer} date={seakDate}></Countdown></div>
                <div className="mint-countdown"><b>Reserve Mint</b>: <Countdown renderer={renderer} date={reserveDate}></Countdown></div> */}
                <div className="mint-countdown"><b>Public Mint - Wave Two</b><br></br> <Countdown renderer={renderer} date={generalDate}></Countdown></div>
              </div>
            </div>
          </div>
        </div>
    </div>
    </>
  );
};

export default Home;
