import React, {createContext, useEffect, useState} from 'react';

export const WalletContext = createContext({
    connected: false,
    address: null,
    mySigner: null
});

export default function UserProvider(props) {
    const [wallet, setWallet] = useState({ 
        connected: false,
        address: null,
        mySigner: null
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // loadUser();

        /*
        EventHandler.on(DiscordEvent.DISCORD_ACCOUNTS_UPDATED, loadUser);
        EventHandler.on(TwitterEvent.TWITTER_ACCOUNTS_UPDATED, loadUser);
        EventHandler.on(WalletEvent.ADD_WALLET, loadUser);
        EventHandler.on(WalletEvent.UPDATE_WALLET, loadUser);
        EventHandler.on(WalletEvent.REMOVE_WALLET, loadUser);

        const sub = AuthenticationService.authData.subscribe(() => {
            loadUser();
        });

        return () => {
            sub.unsubscribe();
            EventHandler.remove(DiscordEvent.DISCORD_ACCOUNTS_UPDATED, loadUser);
            EventHandler.remove(TwitterEvent.TWITTER_ACCOUNTS_UPDATED, loadUser);
            EventHandler.remove(WalletEvent.ADD_WALLET, loadUser);
            EventHandler.remove(WalletEvent.UPDATE_WALLET, loadUser);
            EventHandler.remove(WalletEvent.REMOVE_WALLET, loadUser);
        };
        */
    }, []);

    function loadUser() {
        /*setLoading(true);
        apiGetUserInfo().then(data => {
            setUser(data);
            setLoading(false);
        });
        */
    }

    return (
        <WalletContext.Provider value={{...wallet, loading, reloadUser: loadUser, wallet: wallet, setWallet: setWallet}}>{ props.children }</WalletContext.Provider>
    );
}
