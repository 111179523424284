import React from 'react';
import './styles/reset.css';
import './styles/global.css';
import NavBar from './components/NavBar';
import Home from './components/Home';
import Footer from './components/Footer';
import Mint from './components/Mint';
import WalletProvider from './contexts/Wallet';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <div>
      <WalletProvider>
        <ToastContainer />
        <NavBar />
        <Home />
        <Mint />
        <Footer />
      </WalletProvider>
    </div>
  );
};

export default App;
