import React from "react";
import "../styles/NavBar.css";
import discord from "../assets/discord.png";
import twitter from "../assets/twitter.png";
import instagram from "../assets/instagram.svg";
import opensea from "../assets/opensea.png";
import Hamburger from "./Hamburger";

const NavBar = () => {
  return (
    <div className="NavSection">
      <div className="NavContainer">
        <div className="LogoContainer"></div>
        <div className="MenuContainer">
          <Hamburger />
        </div>
        <div className="NavIconContainer">
          <a
            href="https://twitter.com/SEAKNFT"
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitter} alt="" />
          </a>
          <a
            href="https://www.instagram.com/seaknft/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="" />
          </a>
          <a
            href="https://opensea.io/collection/seaknft"
            target="_blank"
            rel="noreferrer"
          >
            <img src={opensea} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
