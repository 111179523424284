import keccak256 from 'keccak256';
import { MerkleTree } from 'merkletreejs';

import og from './og';
import seaklist from './seaklist';
import reserve from './reserve';

const ogList = new Set(og.map(it => it.toLowerCase()))
const seakList = new Set(seaklist.map(it => it.toLowerCase()))
const reserveList = new Set(reserve.map(it => it.toLowerCase()))

function createMerkleTree(list) {
    const leafNodes = list.map(addr => keccak256(addr))
    return new MerkleTree(leafNodes, keccak256, { sortPairs: true })
}

const ogMerkleTree = createMerkleTree(og)
const seakMerkleTree = createMerkleTree(seaklist)
const reserveMerkleTree = createMerkleTree(reserve)

console.log(`ogMerkleTree - merkle root: ${ogMerkleTree.getHexRoot()}`)
console.log(`seakMerkleTree - merkle root: ${seakMerkleTree.getHexRoot()}`)
console.log(`reserveMerkleTree - merkle root: ${reserveMerkleTree.getHexRoot()}`)

export function isOG(addr) {
    return ogList.has(addr.toLowerCase())
}

export function isSeak(addr) {
    return seakList.has(addr.toLowerCase())
}

export function isReserve(addr) {
    return reserveList.has(addr.toLowerCase())
}

export function getOGMerkleProof(addr) {
    return ogMerkleTree.getHexProof(keccak256(addr))
}

export function getSeakMerkleProof(addr) {
    return seakMerkleTree.getHexProof(keccak256(addr))
}

export function getReserveMerkleProof(addr) {
    return reserveMerkleTree.getHexProof(keccak256(addr))
}