import React, {useContext, useEffect, useState} from 'react';
import prereveal from '../assets/seak-prereveal.gif';
import gradient from '../assets/gradient.jpg';
import '../styles/Mint.css';
import {WalletContext} from '../contexts/Wallet';

import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { ethers } from "ethers";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MintButton from './MintButton';

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: "INFURA_ID", // required
    },
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "web3modal", // Required
      infuraId: "INFURA_ID", // Required
      rpc: "", // Optional if `infuraId` is provided; otherwise it's required
      chainId: 1, // Optional. It defaults to 1 if not provided
      darkMode: false, // Optional. Use dark theme, defaults to false
    },
  }
};

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions, // required
});

const Mint = () => {

  const { loading: userLoading, wallet, setWallet } = useContext(WalletContext);

  const connectWallet = async () => {
    if (window.ethereum) {
      let provider;
      try {
        provider = await web3Modal.connect();
      } catch (e) {
        toast.error(`Unable to connect your wallet: ${e.message}`);
      }
      const web3 = new Web3(provider);
      try {
        await window.ethereum.send("eth_requestAccounts");
      } catch (e) {
        toast.error(`Unable to connect your wallet: ${e.message}`);
        return;
      }
      let accounts;
      try {
        accounts = await web3.eth.getAccounts();
      } catch (e) {
        toast.error(`Unable to connect your wallet: ${e.message}`);
        return;
      }
      const account = accounts[0];
      toast.success('Successfully connected wallet!')

      let myProvider = new ethers.BrowserProvider(window.ethereum);
      let signer = await myProvider.getSigner();

      setWallet({connected: true, address: account, signer: signer});
    } else {
      // Show alert if Ethereum provider is not detected
      toast.warning("Please install MetaMask");
    }
  };

  return (

<>

<div className="section mint-box-section">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-6">
        <div className="mint-box-wrap">
          { wallet.connected
            ? <><MintButton/></>
            : <div className="connect-wallet-wrap">
              <p>Connect your wallet to mint.</p>
                <button type="button" className="btn btn-primary connect" onClick={connectWallet}>Connect Wallet</button>
              </div>
          }
        </div>
      </div>
      <div className="col-lg-6 d-flex justify-content-center">
        <img src={prereveal} alt='RAIN' className="rain-pencil"/>
      </div>
    </div>
  </div>
</div>


</>

    // <div className='MintSection'>
    //   <div className='MintContainer'>
    //     <div className='Web3Container'>
    //       <div className='MintTextContainer'>
    //         <h2>Mint</h2>
    //       </div>
    //       <div className='CheckWalletContainer'>
    //       { wallet.connected
    //         ? <><MintButton/></>
    //         : <><button type="button" className="btn btn-primary connect" onClick={connectWallet}>Connect Wallet</button></>
    //       }
    //       </div>
    //     </div>
    //     <div className='PrerevealContainer'>
    //       <img src={prereveal} alt=''/>
    //     </div>
    //   </div>
    //   <div className='MintGradientContainer'>
    //     <img src={gradient} alt=''/>
    //   </div>
    // </div>
  );
};

export default Mint;
