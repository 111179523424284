import { useState } from "react";
import menu from "../assets/hamburger.png";
import discord from "../assets/discord.png";
import twitter from "../assets/twitter.png";
import instagram from "../assets/instagram.svg";
import opensea from "../assets/opensea.png";
import "../styles/Hamburger.css";

const Hamburger = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="MenuContainer">
      <img
        src={menu}
        onClick={() => {
          setShowMenu((show) => !show);
        }}
        alt=""
        draggable={false}
      />
      {showMenu && (
        <div className="MenuWrapper">
          <a
            href="https://seakllc.gitbook.io/seakllc/"
            target="_blank"
            rel="noreferrer"
          >
            GitBook
          </a>
          <a
            href="https://seakllc.gitbook.io/seakllc/team-introductions"
            target="_blank"
            rel="noreferrer"
          >
            Team
          </a>
          <a
            href="https://seakllc.gitbook.io/seakllc/information"
            target="_blank"
            rel="noreferrer"
          >
            FAQ
          </a>
          <a
            href="https://seakllc.gitbook.io/seakllc/collaborations"
            target="_blank"
            rel="noreferrer"
          >
            Collaborations
          </a>
          <a
            href="https://medium.com/@seaknft"
            target="_blank"
            rel="noreferrer"
          >
            Medium
          </a>
          <div className="MenuIconContainer">
            <a
              href="https://twitter.com/SEAKNFT"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitter} alt="" />
            </a>
            <a
              href="https://www.instagram.com/seaknft/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram} alt="" />
            </a>
            <a
              href="https://opensea.io/collection/seaknft"
              target="_blank"
              rel="noreferrer"
            >
              <img src={opensea} alt="" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hamburger;
