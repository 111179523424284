import React, {useContext, useEffect, useState} from 'react';
import prereveal from '../assets/seak-prereveal.gif';
import gradient from '../assets/gradient.jpg';
import '../styles/Mint.css';
import {WalletContext} from '../contexts/Wallet';
import Lottie from "lottie-react";
import Fireworks from "../lottie/fireworks.json";
import logo from '../assets/text-tilde-dark.svg';

import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import contractJson from "../contract/contract";
import Whitelist, { getReserveMerkleProof, getStaffMerkleProof, getOGMerkleProof, getSeakMerkleProof, isOG, isReserve, isSeak } from '../contract/Whitelist';

import {ethers} from "ethers";

const MintButton = () => {

  const { loading: userLoading, wallet, setWallet, signer, contractAddress } = useContext(WalletContext);
  
  const [status, setStatus] = useState('');
  const [price, setPrice] = useState('');
  const [canMint, setCanMint] = useState(false);

  const [totalSupply, setTotalSupply] = useState('');
  const [maxSupply, setMaxSupply] = useState('');

  const [successMsg, setSuccessMsg] = useState('');
  const [wlMsg, setWlMsg] = useState('');

  const contract = new ethers.Contract(
    contractJson.contractAddress,
    contractJson.abi,
    wallet.signer,
  );

  const canMintNow = async () => {
    const paused = await contract.paused();
    if(paused) {
      return false;
    }
    const saleState = await contract.saleState();
    if(saleState == 0) {
      return false;
    }
    if(saleState == 1) {
      return isOG(wallet.address);
    }
    if(saleState == 2) {
      return isOG(wallet.address) || isSeak(wallet.address)
    }
    if(saleState == 3) {
      return isOG(wallet.address) || isSeak(wallet.address) || isReserve(wallet.address)
    }
    return true;
  }

  const calculateMintPrice = async () => {
    if(isOG(wallet.address)) {
      return String(await contract.ogPrice());
    }
    if(isSeak(wallet.address)) {
      return String(await contract.sklistPrice());
    }
    return String(await contract.reservePrice());
  }

  const mintStatus = async () => {

    console.log('canMintNow? ' + await canMintNow());

    const saleState = await contract.saleState();
    const numMinted = await contract.totalSupply();
    const maxSupply = await contract.maxSupply();
    const supplyLeft = maxSupply - numMinted;

    setTotalSupply(String(numMinted));
    setMaxSupply(String(maxSupply));

    if(isOG(wallet.address)) {  
      setWlMsg(`You are <b>OG Listed </b><span className='alert-address'>${ wallet.address.replace(wallet.address.substring(6,36), "...")}</a>`);
    } else if(isSeak(wallet.address)) {
      setWlMsg(`You are <b>SEAKListed </b><span className='alert-address'>${ wallet.address.replace(wallet.address.substring(6,36), "...")}</a>`);
    } else if(isReserve(wallet.address)) {
      setWlMsg(`You are <b>Reserve Listed </b><span className='alert-address'>${ wallet.address.replace(wallet.address.substring(6,36), "...")}</a>`);
    } else {
      if(saleState < 4) {
        setWlMsg(`You are <b>not</b> on the access lists. <span className='alert-address'>${ wallet.address.replace(wallet.address.substring(6,36), "...")} </a>`);
      }
    }

    const paused = await contract.paused();
    if(paused) {
      return 'Mint is currently paused';
    }

    if(supplyLeft <= 0) {
      return 'Sold out'
    };

    let message = '';
    switch(Number(saleState)) {
      case 0:
        message = 'Mint not stated'
        break;
      case 1:
        message = 'OG Mint';
        break;
      case 2:
        message = 'SEAKLISTED Mint';
        break;
      case 3:
        message = 'Reserve Mint';
        break;
      case 4:
        message = 'General Mint';
        break;
      default:
        message = 'Unknown Status';
    }
    return message;
  }

  const fetchData = async () => {
    const statusMessage = await mintStatus();
    setStatus(statusMessage);

    const canMintStatus = await canMintNow();
    setCanMint(canMintStatus);

    const myPrice = await calculateMintPrice();
    setPrice(String(myPrice));
  };

  useEffect(() => {
    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

const goMint = async () => {
  let response;

  try {
    if(isOG(wallet.address)) {
      let proof = getOGMerkleProof(wallet.address);
      let theirPrice = await contract.ogPrice();

      response = await contract.ogMint(proof, { value: theirPrice });
    } else if(isSeak(wallet.address)) {
      let proof = getSeakMerkleProof(wallet.address);
      let theirPrice = await contract.sklistPrice();

      response = await contract.sklistMint(proof, { value: theirPrice });
    } else if(isReserve(wallet.address)) {
      let proof = getReserveMerkleProof(wallet.address);
      let theirPrice = await contract.reservePrice();

      response = await contract.reserveMint(proof, { value: theirPrice });
    } else {
      let theirPrice = await contract.reservePrice();

      response = await contract.mint({ value: theirPrice });
    }
  } catch (e) {
    if(e.message.split("(").length > 0) {
      toast.warning("Error Minting: " + e.message.split("(")[0]);
    } else {
      toast.warning("Error Minting: " + e.message);
    }
    return;
  }
  
  console.log(response);
  console.log('Yay! Tx hash: ' + response.hash);

  // toast.success(`Congratulations & thank you! You have minted SEAK!`, {
  //   autoClose: true,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   progress: undefined,
  // });

  setSuccessMsg(response.hash)

};

  return (
    <div className='MintButton'>
      { successMsg ? 
        <div className="successfully-minted mt-2">
          <Lottie className="success-fireworks" animationData={Fireworks} loop={true} />
          <div className="logo-wrap text-center">
            <img src={logo} alt='SEAK' className="logo" draggable={false}/>
          </div>
          <div className="success-msg text-center">
            <h4>Successfully minted.</h4> 
          
          View the progress of your transaction <a target="_BLANK" href={'https://etherscan.io/tx/' + successMsg}>here on Etherscan</a>
          </div>
        </div>
        : 
        <>
      {/* { wlMsg.length > 0 &&
        <div class="alert" dangerouslySetInnerHTML={{ __html: wlMsg }}></div>
      } */}
      <div>
        <span className='mint-heading'>Phase</span>: { status }<br /><hr />
        <span className='mint-heading'>Price</span>: { price/1000000000000000000 }<br /><hr />
      </div>
      <div className='mint-button-lower'>
        <div className='supply'>{ totalSupply } / 333</div>
        {canMint 
          ? <button type="button" className="btn btn-primary connect" onClick={goMint}>Mint <b>1</b> SEAK NFT</button>
          : <>Your mint has not started yet.</>
        }
        <br />
        </div>
        </>}
    </div>
  );
};

export default MintButton;
