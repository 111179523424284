import React from "react";
import "../styles/Footer.css";
import discord from "../assets/discord.png";
import twitter from "../assets/twitter.png";
import instagram from "../assets/instagram.svg";
import opensea from "../assets/opensea.png";
import logo from "../assets/text-tilde-dark.svg";

const Footer = () => {
  return (
    <div className="FooterSection">
      <div className="FooterContainer">
        <p>Copyright © 2023 SEAK LLC. | All Rights Reserved | SEAK W!</p>
        <img src={logo} alt="" />
        <div className="IconFooterContainer">
          <a
            href="https://twitter.com/SEAKNFT"
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitter} alt="" />
          </a>
          <a
            href="https://www.instagram.com/seaknft/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="" />
          </a>
          <a
            href="https://opensea.io/collection/seaknft"
            target="_blank"
            rel="noreferrer"
          >
            <img src={opensea} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
